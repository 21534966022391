.swiper-kenburn .swiper-slide {
  position: relative;
}

.swiper-kenburn .swiper-slide img {
  transform: scale(1);
  transition: transform 2.6s ease-out;
  transform-origin: 50% 50%;
  max-width: 100%;
  width: 100%;
}
.swiper-kenburn .swiper-slide.swiper-slide-duplicate-active img,
.swiper-kenburn .swiper-slide.swiper-slide.swiper-slide-active img {
  transform: scale(1.1);
}
