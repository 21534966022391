.gm-style .gm-style-iw-c {
  padding-right: 12px !important;
  padding-bottom: 12px !important;
}
.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

button.gm-ui-hover-effect {
  display: none !important;
}
